import { useEffect, useState } from 'react'
import LogoS from '../../assets/images/2.png'

import {
    faRobot,
    faCode,
    faBrain,
    faCloud,
    faNetworkWired,
    faAtom
} from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() =>{
    const timer = setTimeout(() => {
        setLetterClass('text-animate-hover')
   }, 4000)
   return clearTimeout(timer)
}, [])
  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArr={['A', 'b', 'o', 'u', 't', ' ',]}
              idx={12}
            />
            <img src={LogoS} alt='AI Pro'/>
            <AnimatedLetters
              letterClass={letterClass}
              strArr={ ['e','u','r','N','e','t','i','X']}
              idx={12}
            />
          </h1>
          <p>
          Neurnetix is an AI and ML company creating innovative solutions to solve real-world challenges. 
          We focus on bridging advanced research with practical applications to drive meaningful impact.
          </p>
          <p align="LEFT">
          Our intelligent systems enhance decision-making, automate workflows, and unlock new opportunities. 
          From predictive analytics to custom AI tools, we empower businesses to thrive.
          </p>
          <p>
          We believe in the transformative power of AI to benefit both businesses and communities. 
          At Neurnetix, we are shaping a future where technology drives progress for everyone.
          </p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
            <FontAwesomeIcon icon={faRobot}  color="#DD0031" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faCode} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faBrain} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faCloud} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faNetworkWired} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faAtom} color="#EC4D28" />
            </div> 
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About

import './index.scss'
import {Link, NavLink} from 'react-router-dom'
import LogoS from '../../assets/images/1.png'
import LogoSubtitle from '../../assets/images/3.png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCode, faEnvelope, faHome, faUser } from '@fortawesome/free-solid-svg-icons'
import {faLinkedin, faGithub, faStackOverflow } from '@fortawesome/free-brands-svg-icons'
const Sidebar = ()=> (
     <div className="nav-bar">
      <Link className="logo" to="/">
        <img src={LogoS} alt="Logo" />
        <img className="sub-logo" src={LogoSubtitle} alt="slobodan" />
      </Link>
      <nav>
        <NavLink exact = 'true' activeclassname = 'active' to = '/'>
            <FontAwesomeIcon icon = {faHome} color = '#4d4d4e' />
        </NavLink>
        <NavLink exact = 'true' activeclassname = 'active' className = 'about-link'  to = '/about'>
            <FontAwesomeIcon icon = {faUser} color = '#4d4d4e' />
        </NavLink>
        <NavLink exact = 'true' activeclassname = 'active' className= 'contact-link' to = '/contact'>
            <FontAwesomeIcon icon = {faEnvelope} color = '#4d4d4e' />
        </NavLink>
      </nav>
      <ul>
        <li>
            <a target='_blank' rel='noreferrer' href='https://www.linkedin.com'> {/*https://www.linkedin.com/in/ahmad-adballah/*/}
                <FontAwesomeIcon icon={faLinkedin} color='#4d4d4e'/> 
            </a>
        </li>
        <li>
            <a target='_blank' rel='noreferrer' href='https://github.com'>{/*https://github.com/ahmad1091*/}
                <FontAwesomeIcon icon={faGithub} color='#4d4d4e'/> 
            </a>
        </li>
        <li>
            <a target='_blank' rel='noreferrer' href='https://stackoverflow.com'>{/*https://stackoverflow.com/users/14686151/ahmad-altayeb*/}
                <FontAwesomeIcon icon={faStackOverflow} color='#4d4d4e'/> 
            </a>
        </li>
        <li>
            <a target='_blank' rel='noreferrer' href='https://leetcode.com'>{/*ttps://leetcode.com/u/ahmad1091/*/}
                <FontAwesomeIcon icon={faCode} color='#4d4d4e'/>
            </a>
        </li>
      </ul>
    </div>
)

export default Sidebar
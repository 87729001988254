import LogoS from '../../../assets/images/2.png'
import './index.scss'

const Logo = () => {


  return (
    <div className="logo-container" >
      <img
        className="solid-logo"
        src={LogoS}
        alt="JavaScript,  Developer"
      />

      <svg
        width="559pt"
        height="897pt"
        version="1.0"
        viewBox="0 0 559 897"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          className="svg-container"
          transform="translate(0 457) scale(.1 -.1)"
          fill="none"
        >
          <path
            d="M1335 1686V-913H138V3253H1322L4186-952V3344H5409V-2969H4186L1338 1688M4257 3285H5358V-2885H4217L1309 1801V-905H168V3214H1268L4207-1047V3290ZL5379-2957 4239-1013 5366 3277 4213-2996 1296 3186 156-948 1309 1761 169 3251 1322-922 182 3251 4213-1026 4213-3009 4705 763"
          />
        </g>
      </svg>
    </div>
  )
}

export default Logo
